/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../utils/cookies'
import '../snippets/jumpToSearch'
import '../snippets/toggleSuggesterInHomepage'
import '../snippets/previewScrollOnHomepage'
import '../snippets/scrollToServiceInB2B'
import '../snippets/showMessageAfterClick'
import '../snippets/deleteAccountButtonListener'
import '../snippets/setSizeOfCardInSupplyChainIframe'
import '../snippets/easyCrypt'
import '../snippets/animateAssistPreview'
import '../snippets/paymentMethodSelector'
import '../snippets/updateTimeAgo'
import '../snippets/submitDiscountInputOnFocusOut'
import '../snippets/copyAnimation'
import '../snippets/apiTokensCollapsibleFunctionality'

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'

import Suggester from '../components/suggester/suggester.vue'
import CompanyCollection from '../components/company_collection.vue'
import Loading from '../components/loading.vue'

import * as Sentry from '@sentry/browser'

import lazyLoadComponent from '../utils/lazy_load_component'
import intersectionObserver from '../utils/intersection_observer'
import leaflet from '../snippets/leaflet_maps/leaflet'
import leafletHeat from '../snippets/leaflet_maps/leaflet-heat'
import leafletCluster from '../snippets/leaflet_maps/leaflet-cluster'
import modalTriggerCustom from '../snippets/modalTriggerCustom'
import mapVisualization from '../snippets/mapVisualization'

import 'leaflet/dist/leaflet.css'
import 'htmx.org'
window.htmx = require('htmx.org')

import browserDetect from '../utils/browser_detection'
const browser = browserDetect()?.browser
if (browser) {
  const supportedBrowsers = (
    (browser.name === 'firefox' && parseInt(browser.value) > 65) ||
    (browser.name === 'chrome' && parseInt(browser.value) > 65) ||
    (browser.name === 'safari' && parseInt(browser.value) > 12) ||
    (browser.name === 'opera' && parseInt(browser.value) > 45) ||
    (browser.name === 'msie' && parseInt(browser.value) >= 10)
  )

  if (supportedBrowsers && window.location.hostname === 'implisense.com') {
    Sentry.init({ dsn: 'https://1e1bd0092e124ccdbce056714018e1f5@glitchtip.implisense.com/2' })
  }
}

const defaultOptions = {
  loading: Loading,
  loadingData: {
    props: {
      width: '100%',
      height: '20em',
    }
  }
}

window.location.hostname !== 'implisense.com' ? Vue.config.devtools = true : Vue.config.devtools = false

Vue.use(TurbolinksAdapter)

Vue.directive('click-outside', {
  bind: function (el, binding) {
    const bubble = binding.modifiers.bubble
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler
    document.addEventListener('click', handler)
  },
  unbind: function (el) {
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  }
})
document.addEventListener('turbolinks:load', () => {
  var elems = document.querySelectorAll('.parallax');
  M.Parallax.init(elems);
  window.htmx.process(document.body)

  document.querySelectorAll('[data-behavior="vue"]').forEach(function (element) {
    new Vue({
      el: element,
      components: {
        CompanyCollection,
        EventTimeline: () => import('../components/events/event_timeline'),
        EventFilters: () => import('../components/events/event_filters'),
        SearchFilters: () => import('../components/search_filters/search_filters'),
        DashboardFilters: () => import('../components/search_filters/dashboard_filters'),
        Network: lazyLoadComponent({
          ...defaultOptions,
          lazyComponent: () => import('../components/network/network.vue')
        }),
        NetworkPreview: lazyLoadComponent({
          ...defaultOptions,
          lazyComponent: () => import('../components/network/network_preview.vue')
        }),
        CompanyListCompanyAdder: () => import('../components/company_lists/company_list_company_adder.vue'),
        CompanyListCounter: () => import('../components/company_lists/company_list_counter.vue'),
        CompanyListExporter: () => import('../components/company_lists/company_list_exporter.vue'),
        InfoBox: () => import('../components/info_box.vue'),
        SavedSearches: () => import('../components/saved_searches.vue')
      }
    })
  })
  const suggester = document.querySelector('[data-behavior="suggester"]')
  new Vue({
    el: suggester,
    components: { Suggester }
  })
  const map = document.querySelector('.map')
  if (map) {
    intersectionObserver(map, leaflet)
  }
  const heatMap = document.querySelector('.heat-map')
  if (heatMap) {
    intersectionObserver(heatMap, leafletHeat)
  }
  const clusterMap = document.querySelector('.cluster-map')
  if (clusterMap) {
    intersectionObserver(clusterMap, leafletCluster)
  }
  document.querySelectorAll('.language-selector-dropdown li').forEach((li) => {
    li.addEventListener('click', (item) => {
      let currentLocale = item.target.dataset.lang
      document.documentElement.lang = currentLocale
    })
  })
  if (window.message !== undefined && window.message['heading'] && window.message['body']) {
    setTimeout(function () {
      window.M.toast({
        html: ('<h6>' + window.message['heading'] + '</h6>' +
               '<p>' + window.message['body'] + '</p>' +
               '<button class="btn-flat toast-action">OK</button>'),
        displayLength: 60000
      })
      document.querySelectorAll('.toast-action').forEach(function (toastActionButton) {
        toastActionButton.addEventListener('click', function () {
          let toastElement = document.querySelector('.toast')
          let toastInstance = window.M.Toast.getInstance(toastElement)
          toastInstance.dismiss()
        })
      })
      window.message = undefined
    }, 1000)
  } else if (window.message !== undefined) {
    setTimeout(function () {
      if (window.M && window.M.toast) {
        window.M.toast({
          html: window.message
        })
      }
      window.message = undefined
    }, 1000)
  }
  modalTriggerCustom()
  mapVisualization()
})

document.addEventListener('turbolinks:before-visit', function () {
  document.querySelector('.modal-overlay')?.remove()
  document.querySelector('.right-nav-element')?.classList.remove('hide')
  document.querySelector('.left-nav-element')?.classList.remove('hide')
  if (window.M && window.M.Toast) {
    window.M?.Toast?.dismissAll()
  }
})
document.addEventListener('turbolinks:before-cache', function () {
  document.body.classList.remove('overflow-hidden')
  document.querySelectorAll('.stick-to-top').forEach(function (item) {
    item.classList.remove('stick-to-top')
  })
  const modals = document.querySelectorAll('.modal, .tab-modal')
  modals.forEach(function (elem) {
    let instance = M.Modal.getInstance(elem)
    if (instance) {
      instance.destroy()
    }
  })
  const scrollspies = document.querySelectorAll('.scrollspy')
  scrollspies.forEach(function (elem) {
    let instance = M.ScrollSpy.getInstance(elem)
    if (instance) {
      instance.destroy()
    }
  })
  const selects = document.querySelectorAll('select')
  selects.forEach(function (elem) {
    let instance = M.FormSelect.getInstance(elem)
    if (instance) {
      instance.destroy()
    }
  })
  const tabs = document.querySelectorAll('.tabs')
  tabs.forEach(function (tab) {
    let instance = M.Tabs.init(tab)
    if (instance) {
      instance.destroy()
    }
  })
  modalTriggerCustom()
})

