(() => {
  document.addEventListener('turbolinks:load', () => {
    let esg_show = document.querySelector('#esg-show')
    let iframe = document.querySelector('#esg-show iframe')
    let lastheight = 0
    if (esg_show) {
      setInterval(() => {
        if (iframe?.contentWindow != null && iframe.contentWindow.document.body.offsetHeight != lastheight) {
            lastheight = iframe.contentWindow.document.body.offsetHeight
            esg_show.style.height = iframe.contentWindow.document.body.offsetHeight + 'px'
        }
      }, 500)
    }
  })
})()